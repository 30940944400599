import { useState, useMemo, useEffect, useRef } from 'react';
import {
    InteractionRequiredAuthError,
    IPublicClientApplication,
    RedirectRequest,
    SilentRequest
} from '@azure/msal-browser';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
    AssignedTo,
    JWT_TOKEN_ROLE_KEY,
    MSAL_CONFIG,
    WorkbenchSections
} from 'core/constants/common';
import qs from 'qs';
import { useMsal } from '@azure/msal-react';
import {
    AxiosCacheInstance,
    buildWebStorage,
    setupCache,
    InternalCacheRequestConfig,
    CacheAxiosResponse
} from 'axios-cache-interceptor';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { setCompleteReviewButtonState } from 'core/features/workbenchTabs/workbenchTabsSlice';
import { setOid, setUserRole } from 'core/features/userProfile/userProfileSlice';
import { setIsSearchPackageFetching } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import { setIsExceptionsRequirementsFetching } from 'core/features/exceptions/exceptionsSlice';
import { setIsKeyDocumentFetching } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { setIsTaxesFetching } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { setIsLegalDescriptionFetching } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import { setIsVestingFetching } from 'core/features/examOrderVesting/examOrderVestingSlice';
import { setIsSearchReportFetching } from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import { setIsStartersAndPriorsFetching } from 'core/features/examOrderStartersAndPriorsGroup/examOrderStartersAndPriorsGroupSlice';
import { AppDispatch } from 'core/store/store';

interface CustomInternalCacheRequestConfig extends InternalCacheRequestConfig {
    reqIdx: number;
    showModalLoadingPopout?: boolean;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    showModalLoadingPopout?: boolean;
}

const msalConfig = JSON.parse(localStorage.getItem(MSAL_CONFIG));

export const getToken = async (msalInstance: IPublicClientApplication): Promise<string> => {
    const accounts = await msalInstance.getAllAccounts();
    const silentRequest: SilentRequest = {
        scopes: msalConfig.scopes,
        account: accounts[0],
        forceRefresh: false
    };
    const redirectRequest: RedirectRequest = {
        scopes: msalConfig.scopes
    };
    try {
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            await msalInstance.acquireTokenRedirect(redirectRequest);
        }
    }
};

/**
 * Parses a JSON Web Token (JWT) and decodes its payload.
 * @param {string} token - The JWT as a string.
 * @returns {object} - The decoded payload of the JWT as a JSON object.
 *
 * This function extracts the payload portion of the JWT, which is the middle
 * part of the token (after the first dot). It decodes the base64Url encoded
 * payload, replaces URL-safe characters with their standard equivalents, and
 * then parses it into a JSON object.
 */
export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

// @ts-ignore
export const getRoleFromToken = async (instance: IPublicClientApplication) => {
    const token: string = await getToken(instance);
    const jwt = parseJwt(token);
    const extension_pulse_roles = jwt?.[JWT_TOKEN_ROLE_KEY];
    return extension_pulse_roles?.length ? extension_pulse_roles : [];
};

const axiosInstance = axios.create({
    baseURL: msalConfig?.apiUrl || '',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
}) as AxiosCacheInstance;

setupCache(axiosInstance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            document.dispatchEvent(new CustomEvent('pulseLogout'));
            return;
        }
        if (error?.response?.status === 403) {
            window.location.href = `${window.location.origin}/unauthorized`;
        }
        if (!error.response) {
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

const handleSyncingLoaderState = (
    dispatch: AppDispatch,
    section: keyof typeof WorkbenchSections,
    state: boolean
) => {
    switch (section) {
        case WorkbenchSections.taxes:
            dispatch(setIsTaxesFetching(state));
            break;
        case WorkbenchSections.vesting:
            dispatch(setIsVestingFetching(state));
            break;
        case WorkbenchSections.legalDescription:
            dispatch(setIsLegalDescriptionFetching(state));
            break;
        case WorkbenchSections.exceptionsRequirements:
            dispatch(setIsExceptionsRequirementsFetching(state));
            break;
        case WorkbenchSections.searchReport:
            dispatch(setIsSearchReportFetching(state));
            break;
        case WorkbenchSections.keyDocuments:
            dispatch(setIsKeyDocumentFetching(state));
            break;
        case WorkbenchSections.searchPackage:
            dispatch(setIsSearchPackageFetching(state));
            break;
        case WorkbenchSections.startersAndPriors:
            dispatch(setIsStartersAndPriorsFetching(state));
            break;
        default:
            return false;
    }
};

export const useAxiosLoader = (initialLoading = true) => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(initialLoading);
    const mountedRef = useRef(false);
    const isPopoutFinalReview = useAppSelector(
        (state) => state.workbenchTabsData.isPopoutFinalReview
    );
    const isPopoutFinalReviewRef = useRef(false);
    const dispatch = useAppDispatch();
    let requestsCount: CustomInternalCacheRequestConfig[] = [];
    let requestsIndex = 0;

    // Update the ref value whenever isPopoutFinalReview changes
    useEffect(() => {
        isPopoutFinalReviewRef.current = isPopoutFinalReview;
    }, [isPopoutFinalReview]);

    const addRequest = (config: CustomInternalCacheRequestConfig) => {
        if (!mountedRef.current) return;
        if (
            config.url.includes('configManager') ||
            config.url.includes('test') ||
            config.url.includes('OCR') ||
            config.showModalLoadingPopout === false
        ) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
        requestsIndex = requestsIndex + 1;
        const httpMethods = new Set([
            'DELETE',
            'PATCH',
            'PUT',
            'POST',
            'delete',
            'patch',
            'put',
            'post'
        ]);
        // Disabling the "Complete Review" button when any changes are done on the workbench
        // and final review is in popped out state.
        if (
            location.pathname.includes('workbench') &&
            isPopoutFinalReviewRef.current &&
            httpMethods.has(config.method)
        ) {
            dispatch(setCompleteReviewButtonState(true));
        }
        config['reqIdx'] = requestsIndex;
        requestsCount.push(config);
        // console.log('Add: ', requestsCount);
    };

    const removeRequest = (config: CustomInternalCacheRequestConfig) => {
        if (!mountedRef.current) return;
        requestsCount = requestsCount.filter((arr) => arr.reqIdx !== config.reqIdx);
        if (!requestsCount.length) {
            setIsLoading(false);
        }
    };

    /**
     * Fetches a token, extracts the oid, and updates the Redux store.
     * Runs when `instance` changes.
     */
    useEffect(() => {
        (async () => {
            const token: string = await getToken(instance);
            const jwt = parseJwt(token);
            const oid = jwt?.oid;
            dispatch(setOid(oid));
            const role = await getRoleFromToken(instance);
            dispatch(setUserRole(role));
        })();
    }, [instance]);

    const interceptors = useMemo(
        () => ({
            request: async (config: CustomInternalCacheRequestConfig) => {
                const token: string = await getToken(instance);
                config.headers.authorization = `Bearer ${token}`;
                if (config.data && config.data.loader === false) {
                    handleSyncingLoaderState(dispatch, config.data.section, true);
                    requestsCount = requestsCount.filter(
                        (arr) => arr.reqIdx !== config.reqIdx
                    );
                } else {
                    addRequest(config);
                }
                return config;
            },
            response: (response: CacheAxiosResponse): CacheAxiosResponse => {
                try {
                    const parsedResponseData = response.config.data
                        ? JSON.parse(response.config.data)
                        : null;
                    if (parsedResponseData && parsedResponseData.loader === false) {
                        handleSyncingLoaderState(dispatch, parsedResponseData.section, false);
                    }
                    removeRequest(response.config as CustomInternalCacheRequestConfig);
                    return response;
                } catch (error) {
                    console.error('Error in parsing the background sync request', error);
                    removeRequest(response.config as CustomInternalCacheRequestConfig);
                    return response;
                }
            },
            error: (error: AxiosError) => {
                // for 504 - timeout error, response object not found inside error
                // so reading config directly from error object
                const config = (error?.response?.config ??
                    error?.config) as CustomInternalCacheRequestConfig;
                removeRequest(config);
                return Promise.reject(error);
            }
        }),
        []
    );

    useEffect(() => {
        mountedRef.current = true;
        const reqInterceptor = axiosInstance.interceptors.request.use(
            interceptors.request,
            interceptors.error
        );
        const resInterceptor = axiosInstance.interceptors.response.use(
            interceptors.response,
            interceptors.error
        );
        return () => {
            mountedRef.current = false;
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return [isLoading];
};

export default {
    get: <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(url, { cache: false, ...config }),

    getWithCache: <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(url, { cache: { ttl: Number.MAX_SAFE_INTEGER }, ...config }),

    getPdfFile: <T>(url: string): Promise<AxiosResponse<T>> =>
        axiosInstance(url, {
            cache: false,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                accept: 'text/plain'
            }
        }),
    postPdfFile: <T, D>(
        url: string,
        data?: D,
        config?: CustomAxiosRequestConfig
    ): Promise<AxiosResponse<T>> =>
        axiosInstance(url, {
            cache: false,
            method: 'POST',
            responseType: 'arraybuffer',
            data,
            headers: {
                accept: 'application/pdf'
            },
            ...config
        }),
    getPngFile: <T>(url: string): Promise<AxiosResponse<T>> =>
        axiosInstance(url, {
            cache: false,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                accept: 'image/png'
            }
        }),

    getPaged: <T>(args: {
        url: string;
        pageNumber: number;
        pageSize: number;
        search?: string;
        orderIds?: string[];
        countyIds?: number[];
        stateIds?: number[];
        businessSegmentIds?: number[];
        actionsIds?: number[];
        productTypeIds?: number[];
        clientIds?: string[];
        statusIds?: string[];
        orderFilter?: string;
        sorting?: {
            fieldSorting: { name: string; direction: 0 | 1 }[];
        };
        fromDate?: string;
        toDate?: string;
        isRush?: boolean;
        assigned?: AssignedTo;
        params?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(args.url, {
            cache: false,
            params: {
                Page: args.pageNumber,
                PageSize: args.pageSize,
                Search: args.search,
                OrderIds: args.orderIds,
                CountyIds: args.countyIds,
                StateIds: args.stateIds,
                BusinessSegmentIds: args.businessSegmentIds,
                ActionIds: args.actionsIds,
                ProductTypeIds: args.productTypeIds,
                ClientIds: args.clientIds,
                StatusIds: args.statusIds,
                AssignedUserIds: args.orderFilter,
                Sorting: args.sorting,
                fromDate: args.fromDate,
                toDate: args.toDate,
                IsRush: args.isRush,
                Assigned: args.assigned
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        }),

    post: <D, R>(
        url: string,
        data?: D,
        config?: CustomAxiosRequestConfig
    ): Promise<AxiosResponse<R>> => axiosInstance.post<R>(url, data, config),

    put: <D, R>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> => axiosInstance.put<R>(url, data, config),

    delete: (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
        axiosInstance.delete(url, { data: config }),

    patch: <T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
        axiosInstance.patch(url, data, config)
};
