import { combineReducers } from '@reduxjs/toolkit';
import businessSegmentsReducer from 'core/features/businessSegments/businessSegmentsSlice';
import actionsReducer from 'core/features/actions/actionsSlice';
import clientReducer from 'core/features/client/clientSlice';
import countyReducer from 'core/features/county/countySlice';
import currentExamOrderReducer from 'core/features/currentExamOrder/currentExamOrderSlice';
import documentTypesReducer from 'core/features/documentTypes/documentTypesSlice';
import documentViewerReducer from 'core/features/documentViewer/documentViewerSlice';
import dragDropReducer from 'core/features/dragDrop/dragDropSlice';
import examCodeBookReducer from 'core/features/examCodeBook/examCodeBookSlice';
import finalReviewReducer from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import examOrderKeyDocumentGroupReducer from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import examOrderLegalDescriptionReducer from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import examOrderSearchPackageGroupReducer from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import examOrderStartersAndPriorsGroupReducer from 'core/features/examOrderStartersAndPriorsGroup/examOrderStartersAndPriorsGroupSlice';
import examOrderSearchReportReducer from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import examOrderTaxesReducer from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import examOrderVestingReducer from 'core/features/examOrderVesting/examOrderVestingSlice';
import exceptionsReducer from 'core/features/exceptions/exceptionsSlice';
import fileDropzoneReducer from 'core/features/fileDropzone/fileDropzoneSlice';
import currentFiltersReducer from 'core/features/filters/filtersSlice';
import fullScreenSidebarReducer from 'core/features/fullScreenSidebar/fullScreenSidebarSlice';
import interestEstateTypesReducer from 'core/features/interestEstateType/interestEstateTypeSlice';
import keyDocumentsGroupingReducer from 'core/features/keyDocumentsGrouping/keyDocumentsGroupingSlice';
import legalDescriptionEditorReducer from 'core/features/legalDescriptionEditor/legalDescriptionEditorSlice';
import legalEditorFullScreenReducer from 'core/features/legalDescriptionEditorExtras/legalDescriptionEditorExtrasSlice';
import ocrDataReducer from 'core/features/ocrData/ocrDataSlice';
import orderHistoryReducer from 'core/features/orderHistory/orderHistorySlice';
import ordersReducer from 'core/features/orders/ordersSlice';
import popoutWindowReducer from 'core/features/popoutWindow/popoutWindowSlice';
import productTypesReducer from 'core/features/productTypes/productTypesSlice';
import profileReducer from 'core/features/profile/profileSlice';
import projectStatusesReducer from 'core/features/projectStatus/projectStatusSlice';
import searchPackageGroupingReducer from 'core/features/searchPackageGrouping/searchPackageGroupingSlice';
import startersAndPriorsGroupingReducer from 'core/features/startersAndPriorsGrouping/startersAndPriorsGroupingSlice';
import searchWarningsReducer from 'core/features/searchWarnings/searchWarningsSlice';
import snackbarReducer from 'core/features/snackbar/snackbarSlice';
import currentSortingRowReducer from 'core/features/sorting/sortingSlice';
import statesReducer from 'core/features/states/statesSlice';
import buyerFormReducer from 'core/features/testOrderBuyerForms/testOrderBuyerFormsSlice';
import testOrderFormReducer from 'core/features/testOrderForm/testOrderFormSlice';
import ownerFormReducer from 'core/features/testOrderOwnerForms/testOrderOwnerFormsSlice';
import parcelNumberFormReducer from 'core/features/testOrderParcelForms/testOrderParcelFormsSlice';
import uploadTaxFormReducer from 'core/features/uploadTaxForm/uploadTaxFormSlice';
import uploadKeyDocumentFormReducer from 'core/features/uploadKeyDocumentForm/uploadKeyDocumentFormSlice';
import uploadSearchPackageFormReducer from 'core/features/uploadSearchPackageForm/uploadSearchPackageFormSlice';
import uploadStartersAndPriorsFormReducer from 'core/features/uploadStartersAndPriorsForm/uploadStartersAndPriorsFormSlice';
import inputValidationReducer from 'core/features/validation/inputValidation';
import testOrderFormErrorsReducer from 'core/features/validation/testOrderFormValidation/validationSlice';
import vestingEditorExtrasReducer from 'core/features/vestingOwnerEditorExtras/vestingOwnerEditorExtrasSlice';
import vestingOwnersEditorReducer from 'core/features/vestingOwnersEditor/vestingOwnersEditorSlice';
import workbenchStatusReducer from 'core/features/workbenchStatus/workbenchStatusSlice';
import workbenchTabsReducer from 'core/features/workbenchTabs/workbenchTabsSlice';
import workbenchSidebarReducer from 'core/features/workenchSidebar/workbenchSidebarSlice';
import startersAndPriorsInformationReducer from 'core/features/startersAndPriorsInformation/startersAndPriorsInformation';
import zipCodesReducer from 'core/features/zipCodes/zipCodesSlice';
import userProfileReducer from 'core/features/userProfile/userProfileSlice';
import manualSearchReducer from 'core/features/manualSearch/manualSearchSlice';
import managerOrdersReducer from 'core/features/managerOrders/managerOrdersSlice';
import NotificationBannerReducer from 'core/features/notificationBanner/notificationBannerSlice';
import runsheetReducer from 'core/features/runsheet/runsheetSlice';

const rootReducer = {
    profileData: profileReducer,
    ordersData: ordersReducer,
    statesData: statesReducer,
    countyData: countyReducer,
    notificationBannerData: NotificationBannerReducer,
    currentFiltersData: currentFiltersReducer,
    currentSortingRowData: currentSortingRowReducer,
    clientData: clientReducer,
    testOrderFormData: testOrderFormReducer,
    parcelNumberFormData: parcelNumberFormReducer,
    buyerFormData: buyerFormReducer,
    ownerFormData: ownerFormReducer,
    productTypesData: productTypesReducer,
    projectStatusesData: projectStatusesReducer,
    documentTypesData: documentTypesReducer,
    testOrderFormErrorsData: testOrderFormErrorsReducer,
    interestEstateTypesData: interestEstateTypesReducer,
    currentExamOrderData: currentExamOrderReducer,
    orderHistoryData: orderHistoryReducer,
    legalDescriptionEditorData: legalDescriptionEditorReducer,
    vestingOwnersEditorData: vestingOwnersEditorReducer,
    businessSegmentsData: businessSegmentsReducer,
    actionsData: actionsReducer,
    zipCodesData: zipCodesReducer,
    workbenchTabsData: workbenchTabsReducer,
    workbenchStatusData: workbenchStatusReducer,
    searchWarningsData: searchWarningsReducer,
    exceptionsData: exceptionsReducer,
    uploadTaxFormData: uploadTaxFormReducer,
    uploadKeyDocumentFormData: uploadKeyDocumentFormReducer,
    uploadSearchPackageFormData: uploadSearchPackageFormReducer,
    uploadStartersAndPriorsFormData: uploadStartersAndPriorsFormReducer,
    ocrData: ocrDataReducer,
    documentViewerData: documentViewerReducer,
    workbenchSidebarData: workbenchSidebarReducer,
    startersAndPriorsInformationData: startersAndPriorsInformationReducer,
    legalEditorExtrasData: legalEditorFullScreenReducer,
    vestingEditorExtrasData: vestingEditorExtrasReducer,
    finalReviewData: finalReviewReducer,
    keyDocumentsGroupingData: keyDocumentsGroupingReducer,
    examOrderKeyDocumentGroupData: examOrderKeyDocumentGroupReducer,
    examOrderVestingData: examOrderVestingReducer,
    examOrderLegalDescriptionData: examOrderLegalDescriptionReducer,
    examCodeBookData: examCodeBookReducer,
    snackbarData: snackbarReducer,
    examOrderSearchPackageGroupData: examOrderSearchPackageGroupReducer,
    examOrderStartersAndPriorsGroupData: examOrderStartersAndPriorsGroupReducer,
    searchPackageGroupingData: searchPackageGroupingReducer,
    startersAndPriorsGroupingData: startersAndPriorsGroupingReducer,
    examOrderSearchReportData: examOrderSearchReportReducer,
    examOrderTaxesData: examOrderTaxesReducer,
    fullScreenSidebarData: fullScreenSidebarReducer,
    dragDropData: dragDropReducer,
    inputValidationData: inputValidationReducer,
    popoutWindowData: popoutWindowReducer,
    fileDropzoneData: fileDropzoneReducer,
    userProfileData: userProfileReducer,
    manualSearchData: manualSearchReducer,
    managerOrdersData: managerOrdersReducer,
    runsheetData: runsheetReducer
};

export default combineReducers(rootReducer);
